import * as sysApiCore from '@shure/shared/angular/data-access/system-api/core';
import {
	DeviceModel,
	DeviceState,
	EthernetPowerSourceType,
	IpMode,
	NetworkInterface,
	Placement,
	DeviceMuteControlFunction,
	DeviceMeteringMode,
	DeviceCompatibilityLevel,
	DeviceLastAuthenticationStatus8021x,
	AutomixMicOptimizationMode,
	DeviceBatteryState,
	DeviceRfStrength,
	ChannelDeviceSpecificEqContour,
	AutomixNoiseDetectionSensitivity,
	AutomixNoiseRemovalLevel,
	AnalogOutputMode,
	AnalogLogicPreset,
	DeviceNetworkMode,
	DeviceMicState,
	THREE_DASHES,
	DeviceUsbTerminalType,
	DeviceMuteControlMode,
	DeviceConferenceOperationMode,
	CoverageMode,
	DeviceBodypackMicrophoneMode
} from '@shure/shared/angular/data-access/system-api/models';

import { mapValueOrDefault } from './value-or-default.mapper';

/**
 * Map model string from system api to internal `DeviceModel`.
 *
 * **Defaults to `DeviceModel.UNKNOWN`**
 */
export function mapDeviceModelFromSysApi(model: string | null | undefined): DeviceModel {
	switch (model) {
		case 'AD4D':
			return DeviceModel.AD4D;
		case 'AD4Q':
			return DeviceModel.AD4Q;
		case 'MXA920-S':
			return DeviceModel.MXA920S;
		case 'MXA920-R':
			return DeviceModel.MXA920R;
		case 'MXA-MUTE':
			return DeviceModel.MXAMUTE;
		case 'MXN5-C':
			return DeviceModel.MXN5C;
		case 'MXN6-P':
			return DeviceModel.MXN6P;
		case 'P300':
			return DeviceModel.P300;
		case 'MXA910':
			return DeviceModel.MXA910;
		case 'MXA710-4FT':
			return DeviceModel.MXA7104FT;
		case 'MXA710-2FT':
			return DeviceModel.MXA7102FT;
		case 'ANIUSB-MATRIX':
			return DeviceModel.ANIUSB;
		case 'IMX-Room':
			return DeviceModel.IMXROOM;
		case 'IMX-Compute':
			return DeviceModel.IMXCOMPUTE;
		case 'MXA901-R':
			return DeviceModel.MXA901R;
		case 'MXA902-S':
			return DeviceModel.MXA902S;
		case 'MXA310':
			return DeviceModel.MXA310;
		case 'MXWAPX':
			return DeviceModel.MXWAPX;
		case 'MXWAPX4':
			return DeviceModel.MXWAPX;
		case 'MXWNDX4':
			return DeviceModel.MXWNDX4;
		case 'MXWNDX8':
			return DeviceModel.MXWNDX8;
		case 'MXWNDX4G':
			return DeviceModel.MXWNDX4G;
		case 'MXWNDX8G':
			return DeviceModel.MXWNDX8G;
		case 'MXN-AMP':
			return DeviceModel.MXNAMP;
		case 'MXW1X':
			return DeviceModel.MXW1X;
		case 'MXW2X':
			return DeviceModel.MXW2X;
		case 'MXW6X':
			return DeviceModel.MXW6X;
		case 'MXW8X':
			return DeviceModel.MXW8X;
		case 'MXWAPXD2':
			return DeviceModel.MXWAPXD2;
		case 'ULXD4Q':
			return DeviceModel.ULXD4Q;
		case 'ULXD4':
			return DeviceModel.ULXD1RX;
		case 'AD600':
			return DeviceModel.AD600;
		case 'SBC250':
			return DeviceModel.JINJICH2;
		case 'SBC450':
			return DeviceModel.JINJICH4;
		case 'SBC850':
			return DeviceModel.JINJICH8;
		case 'ANX4':
			return DeviceModel.MCR;
		case 'QLXD4':
			return DeviceModel.QLXD;
		default:
			return DeviceModel.UNKNOWN;
	}
}

/**
 * Map `DeviceStatus` to internal `DeviceState`.
 *
 * **Defaults to `DeviceState.Error`**
 */
export function mapDeviceStateFromSysApi(model: sysApiCore.DeviceStatus): DeviceState {
	switch (model) {
		case sysApiCore.DeviceStatus.Discovered:
			return DeviceState.Discovered;
		case sysApiCore.DeviceStatus.Error:
			return DeviceState.Error;
		case sysApiCore.DeviceStatus.Offline:
			return DeviceState.Offline;
		case sysApiCore.DeviceStatus.Online:
			return DeviceState.Online;
		default:
			return DeviceState.Error;
	}
}

/**
 * Map coverage mode string to internal `CoverageMode`.
 *
 * **Defaults to `CoverageMode.Automatic`**.
 */
export function mapCoverageModeFromSysApi(mode: sysApiCore.CoverageMode | undefined): CoverageMode {
	switch (mode) {
		case sysApiCore.CoverageMode.Automatic:
			return CoverageMode.Automatic;
		case sysApiCore.CoverageMode.Manual:
		default:
			return CoverageMode.Manual;
	}
}

export function mapEthernetPowerSourceFromSysApi(
	source: sysApiCore.EthernetPowerSourceType | undefined
): EthernetPowerSourceType {
	switch (source) {
		case sysApiCore.EthernetPowerSourceType.Poe:
			return EthernetPowerSourceType.Poe;
		case sysApiCore.EthernetPowerSourceType.PoePlus:
			return EthernetPowerSourceType.PoePlus;
		case sysApiCore.EthernetPowerSourceType.PoePlusPlus:
			return EthernetPowerSourceType.PoePlusPlus;
		case sysApiCore.EthernetPowerSourceType.Unknown:
			return EthernetPowerSourceType.Unknown;
		default:
			return EthernetPowerSourceType.Unknown;
	}
}

/**
 * Map system api IpMode to internal IpMode.
 *
 * **Defaults to `IpMode.Static` when undefined**
 */
export function mapIpModeFromSysApi(mode: sysApiCore.IpMode | null | undefined): IpMode {
	return mode && mode === sysApiCore.IpMode.Dhcp ? IpMode.DHCP : IpMode.STATIC;
}

export const VIRTUAL_IP_PLACEHOLDER = '---.---.---.---';
export type SysApiNetwork = {
	interface?: {
		ipMode: sysApiCore.IpMode | null;
		gateway: string | null;
		ipAddress: string | null;
		macAddress: string | null;
		subnetMask: string | null;
	};
};

/**
 * Map a network interface from sys api.
 *
 * **Will us `mapValueOrDefault` for mapping individual values**
 * **Will assign `VIRTUAL_IP_PLACEHOLDER` when virtual is true**
 */
export function mapNetworkInterfaceFromSysApi(virtual: boolean, network: SysApiNetwork | null): NetworkInterface {
	return {
		ipMode: mapIpModeFromSysApi(network?.interface?.ipMode),
		gateway: virtual ? VIRTUAL_IP_PLACEHOLDER : mapValueOrDefault(network?.interface?.gateway),
		ipAddress: virtual ? VIRTUAL_IP_PLACEHOLDER : mapValueOrDefault(network?.interface?.ipAddress),
		macAddress: virtual ? VIRTUAL_IP_PLACEHOLDER : mapValueOrDefault(network?.interface?.macAddress),
		subnetMask: virtual ? VIRTUAL_IP_PLACEHOLDER : mapValueOrDefault(network?.interface?.subnetMask)
	};
}

/**
 * Map placement string from system api to internal `Placement`.
 *
 * **Defaults to `Placement.NotConfigured`**
 */
export function mapPlacementFromSysApi(placement: sysApiCore.Placement | undefined): Placement {
	switch (placement) {
		case sysApiCore.Placement.Ceiling:
			return Placement.Ceiling;
		case sysApiCore.Placement.Table:
			return Placement.Table;
		case sysApiCore.Placement.WallHorizontal:
			return Placement.WallHorizontal;
		case sysApiCore.Placement.WallVertical:
			return Placement.WallVertical;
		default:
			return Placement.NotConfigured;
	}
}

export function mapAudioMuteControlFunctionFromSysApi(
	muteControlFunction?: sysApiCore.MuteControlFunction
): DeviceMuteControlFunction {
	switch (muteControlFunction) {
		case sysApiCore.MuteControlFunction.Disabled:
			return DeviceMuteControlFunction.Disabled;
		case sysApiCore.MuteControlFunction.Local:
			return DeviceMuteControlFunction.Local;
		case sysApiCore.MuteControlFunction.LogicOut:
			return DeviceMuteControlFunction.LogicOut;
		default:
			return DeviceMuteControlFunction.Disabled;
	}
}

export function mapMeteringModeFromSysApi(mode?: sysApiCore.MeteringMode): DeviceMeteringMode {
	switch (mode) {
		case sysApiCore.MeteringMode.PreFader:
			return DeviceMeteringMode.PreFader;
		case sysApiCore.MeteringMode.PostFader:
			return DeviceMeteringMode.PostFader;
		default:
			return DeviceMeteringMode.PreFader;
	}
}

export function mapDeviceCompatibilityLevelFromSysApi(
	compatibilityLevel: sysApiCore.CompatibilityLevel
): DeviceCompatibilityLevel {
	switch (compatibilityLevel) {
		case sysApiCore.CompatibilityLevel.CompatibleLatest:
			return DeviceCompatibilityLevel.CompatibleLatest;
		case sysApiCore.CompatibilityLevel.CompatibleNew:
			return DeviceCompatibilityLevel.CompatibleNew;
		case sysApiCore.CompatibilityLevel.CompatibleOld:
			return DeviceCompatibilityLevel.CompatibleOld;
		case sysApiCore.CompatibilityLevel.IncompatibleTooNew:
			return DeviceCompatibilityLevel.IncompatibleTooNew;
		case sysApiCore.CompatibilityLevel.IncompatibleTooOld:
			return DeviceCompatibilityLevel.IncompatibleTooOld;
		default:
			return DeviceCompatibilityLevel.CompatibleLatest;
	}
}

export function mapDeviceLastAuthenticationStatus8021xFromSysApi(
	lastAuthenticationStatus8021X: sysApiCore.LastAuthenticationStatus8021X
): DeviceLastAuthenticationStatus8021x {
	switch (lastAuthenticationStatus8021X) {
		case sysApiCore.LastAuthenticationStatus8021X.NeverAttempted:
			return DeviceLastAuthenticationStatus8021x.NeverAttempted;
		case sysApiCore.LastAuthenticationStatus8021X.Success:
			return DeviceLastAuthenticationStatus8021x.Success;
		case sysApiCore.LastAuthenticationStatus8021X.Failure:
			return DeviceLastAuthenticationStatus8021x.Failure;
		default:
			return DeviceLastAuthenticationStatus8021x.NeverAttempted;
	}
}

export function mapMicOptimizationModeFromSysApi(
	mode: sysApiCore.MicOptimizationMode | undefined
): AutomixMicOptimizationMode {
	switch (mode) {
		case sysApiCore.MicOptimizationMode.Normal:
			return AutomixMicOptimizationMode.Normal;
		case sysApiCore.MicOptimizationMode.Mxa910:
			return AutomixMicOptimizationMode.Mxa910;
		case sysApiCore.MicOptimizationMode.Mxa310:
			return AutomixMicOptimizationMode.Mxa310;
		case sysApiCore.MicOptimizationMode.Mxa710:
			return AutomixMicOptimizationMode.Mxa710;
		case sysApiCore.MicOptimizationMode.Mxa901:
			return AutomixMicOptimizationMode.Mxa901;
		case sysApiCore.MicOptimizationMode.Mxa902:
			return AutomixMicOptimizationMode.Mxa902;
		case sysApiCore.MicOptimizationMode.Mxa920:
			return AutomixMicOptimizationMode.Mxa920;
		default:
			return AutomixMicOptimizationMode.Normal;
	}
}

export function mapMicStatusFromSysApi(micStatus: sysApiCore.MicStatus | null | undefined): DeviceMicState {
	switch (micStatus) {
		case sysApiCore.MicStatus.Active:
			return DeviceMicState.Active;
		case sysApiCore.MicStatus.Mute:
			return DeviceMicState.Mute;
		case sysApiCore.MicStatus.Off:
			return DeviceMicState.Off;
		case sysApiCore.MicStatus.OnCharger:
			return DeviceMicState.OnCharger;
		case sysApiCore.MicStatus.OutOfRange:
			return DeviceMicState.OutOfRange;
		case sysApiCore.MicStatus.Standby:
			return DeviceMicState.Standby;
		default:
			return DeviceMicState.Unknown;
	}
}

export function mapBodypackMicrophoneModeFromSysApi(
	mode: sysApiCore.BodypackMicrophoneMode | null | undefined
): DeviceBodypackMicrophoneMode {
	switch (mode) {
		case sysApiCore.BodypackMicrophoneMode.Internal:
			return DeviceBodypackMicrophoneMode.Internal;
		case sysApiCore.BodypackMicrophoneMode.External:
			return DeviceBodypackMicrophoneMode.External;
		default:
			return DeviceBodypackMicrophoneMode.Internal;
	}
}

export function mapBatteryStateFromSysApi(
	batteryState: sysApiCore.BatteryState | null | undefined
): DeviceBatteryState {
	switch (batteryState) {
		case sysApiCore.BatteryState.Calculating:
			return DeviceBatteryState.Calculating;
		case sysApiCore.BatteryState.Charging:
			return DeviceBatteryState.Charging;
		case sysApiCore.BatteryState.Discharging:
			return DeviceBatteryState.Discharging;
		case sysApiCore.BatteryState.Empty:
			return DeviceBatteryState.Empty;
		case sysApiCore.BatteryState.Full:
			return DeviceBatteryState.Full;
		case sysApiCore.BatteryState.OptimalStorage:
			return DeviceBatteryState.OptimalStorage;
		default:
			return DeviceBatteryState.Calculating;
	}
}

export function mapNetworkModeFromSysApi(networkMode: sysApiCore.NetworkMode | null | undefined): DeviceNetworkMode {
	switch (networkMode) {
		case sysApiCore.NetworkMode.Redundant:
			return DeviceNetworkMode.Redundant;
		case sysApiCore.NetworkMode.Split:
			return DeviceNetworkMode.Split;
		case sysApiCore.NetworkMode.Switched:
			return DeviceNetworkMode.Switched;
		case sysApiCore.NetworkMode.Uplink:
			return DeviceNetworkMode.Uplink;
		default:
			return DeviceNetworkMode.Redundant;
	}
}

export function mapRfStrengthFromSysApi(rfActivityLevel: number | null | undefined): DeviceRfStrength {
	if (rfActivityLevel === null || rfActivityLevel === undefined) {
		return DeviceRfStrength.None;
	}
	switch (true) {
		case rfActivityLevel > -70:
			return DeviceRfStrength.Strong;
		case rfActivityLevel > -74:
			return DeviceRfStrength.Moderate;
		case rfActivityLevel > -78:
			return DeviceRfStrength.Low;
		case rfActivityLevel > -85:
			return DeviceRfStrength.VeryLow;
		default:
			return DeviceRfStrength.None;
	}
}

export function mapDeviceEqualizerContourToSysApi(
	eqcontour: ChannelDeviceSpecificEqContour
): sysApiCore.DeviceSpecificEqualizerContour {
	switch (eqcontour) {
		case ChannelDeviceSpecificEqContour.Mxa310:
			return sysApiCore.DeviceSpecificEqualizerContour.Mxa310;
		case ChannelDeviceSpecificEqContour.Mxa710LowShelf:
			return sysApiCore.DeviceSpecificEqualizerContour.Mxa710LowShelf;
		case ChannelDeviceSpecificEqContour.Mxa901HighPass:
			return sysApiCore.DeviceSpecificEqualizerContour.Mxa901HighPass;
		case ChannelDeviceSpecificEqContour.Mxa902HighPass:
			return sysApiCore.DeviceSpecificEqualizerContour.Mxa902HighPass;
		case ChannelDeviceSpecificEqContour.Mxa910:
			return sysApiCore.DeviceSpecificEqualizerContour.Mxa910;
		case ChannelDeviceSpecificEqContour.Mxa910HighPass:
			return sysApiCore.DeviceSpecificEqualizerContour.Mxa910HighPass;
		case ChannelDeviceSpecificEqContour.Mxa910MultiBand:
			return sysApiCore.DeviceSpecificEqualizerContour.Mxa910MultiBand;
		case ChannelDeviceSpecificEqContour.Mxa920HighPass:
			return sysApiCore.DeviceSpecificEqualizerContour.Mxa920HighPass;
		case ChannelDeviceSpecificEqContour.Off:
			return sysApiCore.DeviceSpecificEqualizerContour.Off;
	}
}

export function mapDeviceEqualizerContourFromSysApi(
	eqcontour: sysApiCore.DeviceSpecificEqualizerContour
): ChannelDeviceSpecificEqContour {
	switch (eqcontour) {
		case sysApiCore.DeviceSpecificEqualizerContour.Mxa310:
			return ChannelDeviceSpecificEqContour.Mxa310;
		case sysApiCore.DeviceSpecificEqualizerContour.Mxa710LowShelf:
			return ChannelDeviceSpecificEqContour.Mxa710LowShelf;
		case sysApiCore.DeviceSpecificEqualizerContour.Mxa901HighPass:
			return ChannelDeviceSpecificEqContour.Mxa901HighPass;
		case sysApiCore.DeviceSpecificEqualizerContour.Mxa902HighPass:
			return ChannelDeviceSpecificEqContour.Mxa902HighPass;
		case sysApiCore.DeviceSpecificEqualizerContour.Mxa910:
			return ChannelDeviceSpecificEqContour.Mxa910;
		case sysApiCore.DeviceSpecificEqualizerContour.Mxa910HighPass:
			return ChannelDeviceSpecificEqContour.Mxa910HighPass;
		case sysApiCore.DeviceSpecificEqualizerContour.Mxa910MultiBand:
			return ChannelDeviceSpecificEqContour.Mxa910MultiBand;
		case sysApiCore.DeviceSpecificEqualizerContour.Mxa920HighPass:
			return ChannelDeviceSpecificEqContour.Mxa920HighPass;
		case sysApiCore.DeviceSpecificEqualizerContour.Off:
			return ChannelDeviceSpecificEqContour.Off;
	}
}

export function mapNoiseDetectionSensitivityFromSysApi(
	type: sysApiCore.NoiseDetectionSensitivity | undefined
): AutomixNoiseDetectionSensitivity {
	switch (type) {
		case sysApiCore.NoiseDetectionSensitivity.Off:
			return AutomixNoiseDetectionSensitivity.Off;
		case sysApiCore.NoiseDetectionSensitivity.Low:
			return AutomixNoiseDetectionSensitivity.Low;
		case sysApiCore.NoiseDetectionSensitivity.Medium:
			return AutomixNoiseDetectionSensitivity.Medium;
		case sysApiCore.NoiseDetectionSensitivity.High:
			return AutomixNoiseDetectionSensitivity.High;
		default:
			return AutomixNoiseDetectionSensitivity.Off;
	}
}

export function mapNoiseRemovalLevelFromSysApi(
	type: sysApiCore.NoiseRemovalLevel | undefined
): AutomixNoiseRemovalLevel {
	switch (type) {
		case sysApiCore.NoiseRemovalLevel.Off:
			return AutomixNoiseRemovalLevel.Off;
		case sysApiCore.NoiseRemovalLevel.Low:
			return AutomixNoiseRemovalLevel.Low;
		case sysApiCore.NoiseRemovalLevel.Medium:
			return AutomixNoiseRemovalLevel.Medium;
		case sysApiCore.NoiseRemovalLevel.High:
			return AutomixNoiseRemovalLevel.High;
		default:
			return AutomixNoiseRemovalLevel.Off;
	}
}

export function mapOutputModeFromSysApi(mode: sysApiCore.AnalogAudioOutputMode | undefined): AnalogOutputMode {
	switch (mode) {
		case sysApiCore.AnalogAudioOutputMode.LowImpedance:
			return AnalogOutputMode.LowImpedance;
		case sysApiCore.AnalogAudioOutputMode.SeventyVolt:
			return AnalogOutputMode.SeventyVolt;
		default:
			return AnalogOutputMode.LowImpedance;
	}
}

export function mapAnalogPresetSwitchFromSysApi(
	mode: sysApiCore.AnalogLogicPresetSwitch | undefined
): AnalogLogicPreset {
	switch (mode) {
		case sysApiCore.AnalogLogicPresetSwitch.ModeA:
			return AnalogLogicPreset.ModeA;
		case sysApiCore.AnalogLogicPresetSwitch.ModeB:
			return AnalogLogicPreset.ModeB;
		default:
			return AnalogLogicPreset.ModeA;
	}
}

export function mapFeatureSerialNumFromSysApi(value?: string | null): string {
	return !value ? THREE_DASHES : value;
}

export function mapTerminalTypeFromSysApi(terminalType?: sysApiCore.UsbTerminalType): DeviceUsbTerminalType {
	switch (terminalType) {
		case sysApiCore.UsbTerminalType.SpeakerphoneNoEchoReduction:
			return DeviceUsbTerminalType.SpeakerphoneNoEchoReduction;
		case sysApiCore.UsbTerminalType.EchoCancelingSpeakerphone:
			return DeviceUsbTerminalType.EchoCancelingSpeakerphone;
		default:
			return DeviceUsbTerminalType.SpeakerphoneNoEchoReduction;
	}
}

export function mapMuteButtonModeFromSysApi(mode?: sysApiCore.MuteButtonMode): DeviceMuteControlMode {
	switch (mode) {
		case sysApiCore.MuteButtonMode.PushToMute:
			return DeviceMuteControlMode.PushToMute;
		case sysApiCore.MuteButtonMode.PushToTalk:
			return DeviceMuteControlMode.PushToTalk;
		case sysApiCore.MuteButtonMode.Toggle:
			return DeviceMuteControlMode.Toggle;
		default:
			return DeviceMuteControlMode.PushToMute;
	}
}

export function mapDeviceConferencingOperationModeFromSysApi(
	mode?: sysApiCore.ConferencingOperationMode
): DeviceConferenceOperationMode {
	switch (mode) {
		case sysApiCore.ConferencingOperationMode.Conference:
			return DeviceConferenceOperationMode.Conference;
		case sysApiCore.ConferencingOperationMode.Presentation:
			return DeviceConferenceOperationMode.Presentation;
		case sysApiCore.ConferencingOperationMode.Direct:
			return DeviceConferenceOperationMode.Direct;
		case sysApiCore.ConferencingOperationMode.Custom:
			return DeviceConferenceOperationMode.Custom;
		default:
			return DeviceConferenceOperationMode.Conference;
	}
}
