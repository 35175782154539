import {
	ApolloClientOptions,
	ApolloLink,
	createHttpLink,
	from,
	Operation,
	PossibleTypesMap,
	split
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';
import { firstValueFrom, map, Observable } from 'rxjs';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { ApolloCacheFactory } from './apollo-cache-factory.service';
import { ApolloConnectInfo } from './apollo-connection.models';

export abstract class ApolloConnectionService {
	private retryCountLastSeenOk = 0;
	private retryCountLastSeen = 0;

	private readonly logger: ILogger;
	private apolloConnectInfo$: Observable<ApolloConnectInfo>;

	constructor(
		logger: ILogger,
		private readonly name: string,
		private readonly possibleTypes: PossibleTypesMap,
		private readonly retryCountMax: number,
		private readonly connectInfoProvider$: Observable<{ url: string; apiKey: string }>,
		private readonly apolloCacheFactory: ApolloCacheFactory,
		private readonly oktaIntfService: OktaInterfaceService
	) {
		this.logger = logger.createScopedLogger(`ApolloConnectionService.${this.name}`);
		this.apolloConnectInfo$ = this.connectInfoProvider$.pipe(
			map((connectInfo: { url: string; apiKey: string }) => {
				const apolloConnectInfo = {
					gqlHttpUrl: connectInfo.url,
					gqlWsUrl: connectInfo.url.replace('https', 'wss').replace('http', 'ws'),
					apiKey: connectInfo.apiKey
				};
				// so that when testing, we have a record in the output which BE was used.
				this.logger.debug('constructor', 'apolloConnectionInfo', JSON.stringify(apolloConnectInfo));
				return apolloConnectInfo;
			})
		);
		this.doResetRetryCount();
	}

	public createApolloClientOptions(): ApolloClientOptions<unknown> {
		return {
			cache: this.apolloCacheFactory.createCache(this.name, this.possibleTypes),
			link: this.createLink()
		};
	}

	protected createLink(): ApolloLink {
		this.logger.trace('createLink()', '', { name: this.name });

		const wsLink = this.createWsLink();
		const httpLink = this.createHttpLink();

		return from([
			this.createRetryLink(),
			this.createSuccessLink(),
			this.createErrorLink(),
			this.createContextConnectInfo(),
			this.createSplitLink(wsLink, httpLink)
		]);
	}

	protected createContextConnectInfo(): ApolloLink {
		return setContext(async () => {
			this.logger.trace('withConnectInfo', 'waiting');
			const connectInfo = await firstValueFrom(this.apolloConnectInfo$);
			this.logger.trace('withConnectInfo', 'available', { connectInfo });
			return { connectInfo };
		});
	}

	protected createSuccessLink(): ApolloLink {
		// If data is coming back, we have verified that the connection to sysapi server is ok
		// and we can reset the retry counter.
		return new ApolloLink((operation, forward) => {
			operation.setContext(({ headers = {} }) => ({
				headers: {
					...headers,
					Authorization: `Bearer ${this.oktaIntfService.getAccessTokenJWT()}`
				}
			}));
			return forward(operation).map((data) => {
				this.doResetRetryCount();
				return data;
			});
		});
	}

	protected createErrorLink(): ApolloLink {
		return onError(({ networkError }) => {
			if (networkError) {
				// Catches http error codes, we are ignore this for now,
				// but could indicate authentication issues if used properly on server side
				if ('statusCode' in networkError) {
					this.logger.trace('errorLink', 'httpError', { statusCode: networkError.statusCode });
					return;
				}
				this.logger.trace('errorLink', 'networkError', JSON.stringify(networkError));
				this.onNetworkError();
			}
		});
	}

	protected createRetryLink(): ApolloLink {
		// for Details of the Retry Link
		// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#overview
		// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#avoiding-thundering-herd
		return new RetryLink({
			attempts: {
				retryIf: (error, operation: Operation): boolean => {
					const opDefs = operation.query.definitions[0];
					if ('operation' in opDefs && opDefs.operation !== 'subscription') {
						return false;
					}
					return !!error;
				}
			},
			delay: (count, operation, error): number => {
				const computedDelay = Math.min(10000, count * 1000 * Math.random());
				const logMessage = `Operation: ${operation.operationName}, Variables: ${
					'variables' in operation ? JSON.stringify(operation.variables, null, 2) : 'n/a'
				}, Error: ${error}, count: ${count}, ComputedDelay: ${computedDelay}`;
				this.logger.error('retryLink()', 'delay()', logMessage);
				return computedDelay;
			}
		});
	}

	protected createWsLink(): ApolloLink {
		return new GraphQLWsLink(
			// https://the-guild.dev/graphql/ws/docs/interfaces/client.ClientOptions
			createClient({
				url: () =>
					firstValueFrom(this.apolloConnectInfo$).then((connectInfo) => {
						const uri = new URL(connectInfo.gqlWsUrl);
						uri.searchParams.set('access_token', this.oktaIntfService.getAccessTokenJWT());
						return uri.toString();
					}),
				lazy: true, // establish on first subscription, tear down on last unsubscribe.
				retryAttempts: 0xffff, // default is 5
				shouldRetry: () => true, // default is to only retry on "close", not errors
				on: {
					connected: () => {
						this.logger.debug('GraphQLWsLink:createClient', 'websocket connected');
					},
					error: (error) => {
						this.logger.error('GraphQLWsLink:createClient', 'websocket error', JSON.stringify(error));
					}
				}
			})
		);
	}

	protected createHttpLink(): ApolloLink {
		return createHttpLink({
			uri: (operation) => {
				const { connectInfo } = operation.getContext();
				return connectInfo.gqlHttpUrl;
			}
		});
	}

	protected createSplitLink(wsLink: ApolloLink, httpLink: ApolloLink): ApolloLink {
		// split operation based on type. Queries and mutations use http and
		// subscriptions use websockets
		return split(
			({ query }) => {
				const definition = getMainDefinition(query);
				return (
					definition.kind === Kind.OPERATION_DEFINITION &&
					definition.operation === OperationTypeNode.SUBSCRIPTION
				);
			},
			wsLink,
			httpLink
		);
	}

	private onNetworkError(): void {
		this.logger.trace('onNetworkError', '');
		// Currently we are not doing anything, but this indicate that the connection is broken,
		// and we should show this to the user to take action.
	}

	private shouldRetry(count: number, operation: Operation, error: unknown): boolean {
		// count comes from Apollo RetryLink and seems to occasionally being reset,
		// but the reset is not related to e.g. successfully subscriptions
		this.retryCountLastSeen = count;

		const retryCount = this.getRetryCount();
		this.logger.trace('shouldRetry', '', {
			retryCount,
			retryCountLastSeen: this.retryCountLastSeen,
			retryCountLastSeenOk: this.retryCountLastSeenOk,
			count,
			operation,
			error
		});
		return retryCount < this.retryCountMax;
	}

	private getRetryDelay(_count: number): number {
		return 2000 * Math.random();
	}

	private doResetRetryCount(): void {
		this.retryCountLastSeenOk = this.retryCountLastSeen;
	}

	private getRetryCount(): number {
		return Math.max(0, this.retryCountLastSeen - this.retryCountLastSeenOk);
	}
}
