import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import {
	DeviceConditionSeverity,
	DeviceState,
	InventoryDevice,
	SortedDeviceConditionCollection
} from '@shure/cloud/shared/models/devices';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { DeviceCompatibilityLevel } from '@shure/shared/angular/data-access/system-api/models';
import { ILogger } from '@shure/shared/angular/utils/logging';

@Injectable()
export class DeviceStatusService {
	public static readonly lowBatteryThreshold = 5; // 5%
	public static readonly veryLowBatteryThreshold = 2; // 2%

	private logger: ILogger;

	constructor(
		logger: ILogger,
		private translocoService: TranslocoService,
		@Inject(APP_ENVIRONMENT) private appEnv: AppEnvironment
	) {
		this.logger = logger.createScopedLogger('DeviceStatusService');
	}

	public conditionsFor(device: InventoryDevice): SortedDeviceConditionCollection {
		const conditions = new SortedDeviceConditionCollection();
		if (device.deviceState !== DeviceState.Online) {
			return conditions;
		}

		this.populateCompatibilityConditions(conditions, device);

		if (this.appEnv.showProxiedDevices) {
			this.populateBatteryLevelConditions(conditions, device);
		}

		return conditions;
	}

	private populateCompatibilityConditions(
		conditions: SortedDeviceConditionCollection,
		device: InventoryDevice
	): void {
		if (device.compatibility === DeviceCompatibilityLevel.IncompatibleTooOld) {
			conditions.add({
				severity: DeviceConditionSeverity.High,
				description: this.translocoService.translate(
					'cloud.device-management.status.device-firmware-incompatible'
				)
			});
		}
	}

	private populateBatteryLevelConditions(conditions: SortedDeviceConditionCollection, device: InventoryDevice): void {
		if (!device.features.batteryLevel?.isMissing) {
			const batteryLevel = device.features.batteryLevel?.percentage ?? Number.NaN;
			if (batteryLevel <= DeviceStatusService.veryLowBatteryThreshold) {
				conditions.add({
					severity: DeviceConditionSeverity.High,
					description: this.translocoService.translate(
						'cloud.device-management.status.mic-battery-very-low',
						{
							percent: this.formatBatteryLevel(batteryLevel)
						}
					)
				});
			} else if (batteryLevel <= DeviceStatusService.lowBatteryThreshold) {
				conditions.add({
					severity: DeviceConditionSeverity.Moderate,
					description: this.translocoService.translate('cloud.device-management.status.mic-battery-low', {
						percent: this.formatBatteryLevel(batteryLevel)
					})
				});
			}
		}

		this.logger.trace(
			'conditionsFor',
			device.name,
			JSON.stringify({
				conditions: conditions.conditions
			})
		);
	}

	private formatBatteryLevel(batteryLevel: number): string {
		return Number.isNaN(batteryLevel) ? '--' : `${batteryLevel}%`;
	}
}
