import { Injectable, signal } from '@angular/core';
import assertNever from 'assert-never';
import sortUniq from 'lodash-es/sortedUniq';
import union from 'lodash-es/union';
import { BehaviorSubject, retry, Subject, take, takeUntil, tap } from 'rxjs';

import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { DeviceTagsApiService } from '../api/device-tags-api.service';

import {
	OrgTagsQueryGQL,
	OrgTagsQueryOpResult,
	OrgTagsSubscriptionGQL,
	OrgTagsSubscriptionOpResult
} from './graphql/generated/cloud-sys-api';

@Injectable({ providedIn: 'root' })
export class SysApiDeviceTagApiService implements DeviceTagsApiService {
	public readonly allDeviceTags = signal<string[]>([]);
	private readonly orgTags = new BehaviorSubject<string[]>([]);
	private readonly logger: ILogger;
	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		logger: ILogger,
		private readonly oktaService: OktaInterfaceService,
		private readonly orgTagsQueryGQL: OrgTagsQueryGQL,
		private readonly orgTagsSubscriptionGQL: OrgTagsSubscriptionGQL
	) {
		this.logger = logger.createScopedLogger('SysApiDeviceTagApiService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService,
			onLogOut: this.suspendService
		});
	}

	private initService = (): void => {
		this.logger.trace('initService', 'initializing service');
		this.destroy$ = new Subject();
		this.allDeviceTags.set([]);
		this.subscribeToDeviceOrgTags();
		this.queryDeviceOrgTags();
	};

	private suspendService = (): void => {
		this.destroy$.next();
		this.destroy$.complete();
		this.allDeviceTags.set([]);
	};

	private queryDeviceOrgTags(): void {
		this.orgTagsQueryGQL
			.fetch(
				{},
				{
					fetchPolicy: 'no-cache'
				}
			)
			.pipe(
				take(1),
				retry({
					delay: 10_000,
					resetOnSuccess: true
				}),
				tap(({ data }) => this.procesTagQueryResponse(data))
			)
			.subscribe();
	}

	// Function to process the tags query response.
	// To account for a subsription event being received before the query
	// response, we'll union the existing tags and the new tags,
	// then sortUniq the resulting union.
	private procesTagQueryResponse(data: OrgTagsQueryOpResult): void {
		if (!('tags' in data)) {
			return;
		}
		const tags = data.tags;
		this.allDeviceTags.update((currentTags) => {
			return sortUniq(union(currentTags, tags));
		});
	}

	private subscribeToDeviceOrgTags(): void {
		this.orgTagsSubscriptionGQL
			.subscribe(
				{},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'no-cache'
				}
			)
			.pipe(
				tap((tagEvent) => this.processTagChange(tagEvent.data)),
				retry({
					delay: 10_000,
					resetOnSuccess: true
				}),
				takeUntil(this.destroy$)
			)
			.subscribe();
	}

	private processTagChange(tagChange: OrgTagsSubscriptionOpResult | undefined | null): void {
		if (!tagChange) {
			return;
		}
		switch (tagChange.tags.__typename) {
			case 'TagAddedEvent': {
				const addedTag = tagChange.tags.added;
				if (!this.allDeviceTags().includes(addedTag)) {
					this.allDeviceTags.update((currentTags) => {
						return [...currentTags, addedTag].sort();
					});
				}
				break;
			}
			case 'TagRemovedEvent': {
				const removedTag = tagChange.tags.removed;
				const lookup = this.allDeviceTags().findIndex((tag) => tag === removedTag);
				if (lookup !== -1) {
					this.allDeviceTags.update((currentTags) => {
						currentTags.splice(lookup, 1); // removed the tag
						return [...currentTags];
					});
				}
				break;
			}
			default:
				assertNever(tagChange.tags);
		}
	}
}
