import * as sysApiCore from '@shure/shared/angular/data-access/system-api/core';
import {
	BeamWidth,
	ChannelAnalogLevel,
	ChannelDenoiserLevel,
	ChannelDeverbIntensity,
	ChannelGroup,
	ChannelNoiseReductionLevel,
	ChannelNonLinearProcessingLevel,
	ChannelLogicInputDeviceType,
	ChannelPcPeripheral,
	ChannelPcPeripheralStatus,
	ChannelRole,
	DanteAudioEncryptionStatus,
	DirectOutputTapPoint,
	LoudspeakerOutputPath,
	InputSource,
	ChannelOutputPath,
	SpatialSeparation,
	ChannelLoudspeaker
} from '@shure/shared/angular/data-access/system-api/models';

const channelGroupNames = <string[]>Object.values(ChannelGroup);
export interface EqFilterSysApiData {
	features?: { configuration?: { enabled: boolean } | null };
	description?: {
		constraints?: {
			configuration?: { filterType?: { options: Array<sysApiCore.EqFilterType> | null } | null } | null;
		};
	};
}

export function mapOutputPathFromSysApi(path: sysApiCore.OutputSignalPath): LoudspeakerOutputPath {
	switch (path) {
		case sysApiCore.OutputSignalPath.PostDigitalSignalProcessing:
			return LoudspeakerOutputPath.PostDsp;
		case sysApiCore.OutputSignalPath.PreDigitalSignalProcessing:
			return LoudspeakerOutputPath.PreDsp;
	}
}
/**
 * Map `AudioChannelRole` from channel fragment to internal `ChannelRole`.
 */
export function mapChannelRoleFromSysApi(role: sysApiCore.AudioChannelRole): ChannelRole {
	switch (role) {
		case sysApiCore.AudioChannelRole.Filter:
			return ChannelRole.Filter;
		case sysApiCore.AudioChannelRole.Sink:
			return ChannelRole.Sink;
		case sysApiCore.AudioChannelRole.Source:
			return ChannelRole.Source;
	}
}

/**
 * Map `AudioChannelGroup` from channel fragment to internal `ChannelGroup`.
 */
export function mapChannelGroupFromSysApi(group: sysApiCore.AudioChannelGroup | string): ChannelGroup {
	switch (group) {
		case sysApiCore.AudioChannelGroup.AecReference:
			return ChannelGroup.AecReference;
		case sysApiCore.AudioChannelGroup.Analog_70VOutput:
			return ChannelGroup.Analog70VOutput;
		case sysApiCore.AudioChannelGroup.AnalogAuxInput:
			return ChannelGroup.AnalogAuxInput;
		case sysApiCore.AudioChannelGroup.AnalogOutput:
			return ChannelGroup.AnalogOutput;
		case sysApiCore.AudioChannelGroup.Automix:
			return ChannelGroup.Automix;
		case sysApiCore.AudioChannelGroup.AuxInput:
			return ChannelGroup.AuxInput;
		case sysApiCore.AudioChannelGroup.AuxOutput:
			return ChannelGroup.AuxOutput;
		case sysApiCore.AudioChannelGroup.BluetoothInput:
			return ChannelGroup.BluetoothInput;
		case sysApiCore.AudioChannelGroup.BluetoothOutput:
			return ChannelGroup.BluetoothOutput;
		case sysApiCore.AudioChannelGroup.DanteAnalogInput:
			return ChannelGroup.DanteAnalogInput;
		case sysApiCore.AudioChannelGroup.DanteAuxInput:
			return ChannelGroup.DanteAuxInput;
		case sysApiCore.AudioChannelGroup.DanteInput:
			return ChannelGroup.DanteInput;
		case sysApiCore.AudioChannelGroup.DanteLineInput:
			return ChannelGroup.DanteLineInput;
		case sysApiCore.AudioChannelGroup.DanteLineOutput:
			return ChannelGroup.DanteLineOutput;
		case sysApiCore.AudioChannelGroup.DanteMicInput:
			return ChannelGroup.DanteMicInput;
		case sysApiCore.AudioChannelGroup.DanteOutput:
			return ChannelGroup.DanteOutput;
		case sysApiCore.AudioChannelGroup.DirectOutput:
			return ChannelGroup.DirectOutput;
		case sysApiCore.AudioChannelGroup.Headphone:
			return ChannelGroup.Headphone;
		case sysApiCore.AudioChannelGroup.Lobe:
			return ChannelGroup.Lobe;
		case sysApiCore.AudioChannelGroup.Loudspeaker:
			return ChannelGroup.Loudspeaker;
		case sysApiCore.AudioChannelGroup.Mic:
			return ChannelGroup.Mic;
		case sysApiCore.AudioChannelGroup.MixOutput:
			return ChannelGroup.MixOutput;
		case sysApiCore.AudioChannelGroup.MobileInput:
			return ChannelGroup.MobileInput;
		case sysApiCore.AudioChannelGroup.MobileOutput:
			return ChannelGroup.MobileOutput;
		case sysApiCore.AudioChannelGroup.PcInput:
			return ChannelGroup.PcInput;
		case sysApiCore.AudioChannelGroup.PcOutput:
			return ChannelGroup.PcOutput;
		case sysApiCore.AudioChannelGroup.SignalGeneratorInput:
			return ChannelGroup.SignalGeneratorInput;
		case sysApiCore.AudioChannelGroup.Sum:
			return ChannelGroup.Sum;
		case sysApiCore.AudioChannelGroup.UsbInput:
			return ChannelGroup.UsbInput;
		case sysApiCore.AudioChannelGroup.UsbOutput:
			return ChannelGroup.UsbOutput;
		case sysApiCore.AudioChannelGroup.VirtualAudioInput:
			return ChannelGroup.VirtualAudioInput;
		case sysApiCore.AudioChannelGroup.VirtualAudioOutput:
			return ChannelGroup.VirtualAudioOutput;
		case sysApiCore.AudioChannelGroup.Back:
			return ChannelGroup.Back;
		case sysApiCore.AudioChannelGroup.Generic:
			return ChannelGroup.Generic;
		default: {
			if (typeof group === 'string') {
				return channelGroupNames.includes(group) ? <ChannelGroup>group : ChannelGroup.Generic;
			}
			return ChannelGroup.Generic;
		}
	}
}

export function mapLobeChannelBeamWidthFromSysApi(mode?: sysApiCore.LobeWidth): BeamWidth {
	switch (mode) {
		case sysApiCore.LobeWidth.Medium:
			return BeamWidth.Medium;
		case sysApiCore.LobeWidth.Narrow:
			return BeamWidth.Narrow;
		case sysApiCore.LobeWidth.Wide:
			return BeamWidth.Wide;
		default:
			return BeamWidth.Medium;
	}
}

/**
 * Map `DanteAudioEncryptionStatus` to internal `DanteAudioEncryptionStatus`
 */
export function mapDanteEncryptionStatusFromSysApi(
	status: sysApiCore.DanteAudioEncryptionStatus | undefined
): DanteAudioEncryptionStatus {
	switch (status) {
		case sysApiCore.DanteAudioEncryptionStatus.Active:
			return DanteAudioEncryptionStatus.Active;
		case sysApiCore.DanteAudioEncryptionStatus.Inactive:
			return DanteAudioEncryptionStatus.Inactive;
		case sysApiCore.DanteAudioEncryptionStatus.KeyMismatch:
			return DanteAudioEncryptionStatus.KeyMismatch;
	}

	return DanteAudioEncryptionStatus.Inactive;
}

export function getEnabledFlagForEqualizerFiltersFromSysApi(
	equalizerFilters: EqFilterSysApiData[] | null | undefined
): boolean {
	if (!equalizerFilters) {
		return false;
	}
	return equalizerFilters.some((filter) => filter?.features?.configuration?.enabled);
}

export function isParametricTypeValid(equalizerFilters: EqFilterSysApiData[] | null): boolean {
	if (!equalizerFilters) {
		return false;
	}
	return equalizerFilters.some(
		(filter) =>
			filter?.description?.constraints?.configuration?.filterType?.options?.some(
				(type) => type === sysApiCore.EqFilterType.Parametric
			)
	);
}

export function mapAnalogLevelFromSysApi(level?: sysApiCore.AnalogLevel): ChannelAnalogLevel {
	switch (level) {
		case sysApiCore.AnalogLevel.Aux:
			return ChannelAnalogLevel.Aux;
		case sysApiCore.AnalogLevel.Line:
			return ChannelAnalogLevel.Line;
		case sysApiCore.AnalogLevel.Mic:
			return ChannelAnalogLevel.Mic;
		default:
			return ChannelAnalogLevel.Mic;
	}
}

export function mapAnalogLevelToSysApi(level: ChannelAnalogLevel): sysApiCore.AnalogLevel {
	switch (level) {
		case ChannelAnalogLevel.Aux:
			return sysApiCore.AnalogLevel.Aux;
		case ChannelAnalogLevel.Line:
			return sysApiCore.AnalogLevel.Line;
		case ChannelAnalogLevel.Mic:
			return sysApiCore.AnalogLevel.Mic;
	}
}

export function mapPcPeripheralOptionsFromSysApi(
	pcPeripheralOptions: sysApiCore.PcPeripheral[]
): ChannelPcPeripheral[] {
	return pcPeripheralOptions.map((option: sysApiCore.PcPeripheral) => mapPcPeripheralFromSysApi(option));
}

export function mapPcPeripheralFromSysApi(pcPeripheral: sysApiCore.PcPeripheral): ChannelPcPeripheral {
	return {
		id: pcPeripheral.id,
		name: pcPeripheral.name,
		status: mapPcPeripheralStatusFromSysApi(pcPeripheral)
	};
}

export function mapPcPeripheralStatusFromSysApi(pcPeripheral: sysApiCore.PcPeripheral): ChannelPcPeripheralStatus {
	switch (pcPeripheral.status) {
		case sysApiCore.PcPeripheralStatus.Available:
			return ChannelPcPeripheralStatus.Available;
		case sysApiCore.PcPeripheralStatus.Begin:
			return ChannelPcPeripheralStatus.Begin;
		case sysApiCore.PcPeripheralStatus.End:
			return ChannelPcPeripheralStatus.End;
		case sysApiCore.PcPeripheralStatus.InProgress:
			return ChannelPcPeripheralStatus.InProgress;
		case sysApiCore.PcPeripheralStatus.None:
			return ChannelPcPeripheralStatus.None;
		case sysApiCore.PcPeripheralStatus.Unavailable:
			return ChannelPcPeripheralStatus.Unavailable;
		default:
			return ChannelPcPeripheralStatus.None;
	}
}

export function mapDirectOutputTapPointFromSysApi(status?: sysApiCore.DirectOutputTapPoint): DirectOutputTapPoint {
	switch (status) {
		case sysApiCore.DirectOutputTapPoint.PreProcessingPreGate:
			return DirectOutputTapPoint.PreProcessingPreGate;
		case sysApiCore.DirectOutputTapPoint.PostProcessingPreGate:
			return DirectOutputTapPoint.PostProcessingPreGate;
		case sysApiCore.DirectOutputTapPoint.PreProcessingPostGate:
			return DirectOutputTapPoint.PreProcessingPostGate;
		case sysApiCore.DirectOutputTapPoint.PostProcessingPostGate:
			return DirectOutputTapPoint.PostProcessingPostGate;
		default:
			return DirectOutputTapPoint.PreProcessingPreGate;
	}
}

export function mapLogicInputDeviceType(type: string | null | undefined): ChannelLogicInputDeviceType {
	switch (type) {
		case 'ANI4IN':
			return ChannelLogicInputDeviceType.Ani4In;
		case 'ANI22':
			return ChannelLogicInputDeviceType.Ani22;
		case 'MXA310':
			return ChannelLogicInputDeviceType.Mxa310;
		case 'MXA710_2FT':
			return ChannelLogicInputDeviceType.Mxa7102Ft;
		case 'MXA710_2FT_IMX':
			return ChannelLogicInputDeviceType.Mxa7102FtImx;
		case 'MXA710_4FT':
			return ChannelLogicInputDeviceType.Mxa7104Ft;
		case 'MXA710_4FT_IMX':
			return ChannelLogicInputDeviceType.Mxa7104FtImx;
		case 'MXA901_ROUND_IMX':
			return ChannelLogicInputDeviceType.Mxa901RoundImx;
		case 'MXA902_IMX':
			return ChannelLogicInputDeviceType.Mxa902Imx;
		case 'MXA910':
			return ChannelLogicInputDeviceType.Mxa910;
		case 'MXA910_IMX':
			return ChannelLogicInputDeviceType.Mxa910Imx;
		case 'MXA920':
			return ChannelLogicInputDeviceType.Mxa920;
		case 'MXA920_IMX':
			return ChannelLogicInputDeviceType.Mxa920Imx;
		case 'MXA920_ROUND':
			return ChannelLogicInputDeviceType.Mxa920Round;
		case 'MXA920_ROUND_IMX':
			return ChannelLogicInputDeviceType.Mxa920RoundImx;
		default:
			return ChannelLogicInputDeviceType.Unknown;
	}
}

export function mapNonLinearProcessingLevelFromSysApi(
	level?: sysApiCore.NonLinearProcessingLevel
): ChannelNonLinearProcessingLevel {
	switch (level) {
		case sysApiCore.NonLinearProcessingLevel.Auto:
			return ChannelNonLinearProcessingLevel.Auto;
		case sysApiCore.NonLinearProcessingLevel.Low:
			return ChannelNonLinearProcessingLevel.Low;
		case sysApiCore.NonLinearProcessingLevel.Medium:
			return ChannelNonLinearProcessingLevel.Medium;
		case sysApiCore.NonLinearProcessingLevel.High:
			return ChannelNonLinearProcessingLevel.High;
		default:
			return ChannelNonLinearProcessingLevel.Low;
	}
}

export function mapDenoiserLevelFromSysApi(
	level: sysApiCore.DenoiserLevel = sysApiCore.DenoiserLevel.Low
): ChannelDenoiserLevel {
	switch (level) {
		case sysApiCore.DenoiserLevel.Low:
			return ChannelDenoiserLevel.Low;
		case sysApiCore.DenoiserLevel.Medium:
			return ChannelDenoiserLevel.Medium;
		case sysApiCore.DenoiserLevel.High:
			return ChannelDenoiserLevel.High;
	}
}

export function mapDeverbIntensityLevelFromSysApi(level?: sysApiCore.DeverbIntensity | null): ChannelDeverbIntensity {
	switch (level) {
		case sysApiCore.DeverbIntensity.High:
			return ChannelDeverbIntensity.High;
		case sysApiCore.DeverbIntensity.Medium:
			return ChannelDeverbIntensity.Medium;
		case sysApiCore.DeverbIntensity.Low:
		default:
			return ChannelDeverbIntensity.Low;
	}
}

export function mapNoiseReductionLevelFromSysApi(
	level?: sysApiCore.NoiseReductionLevel | null
): ChannelNoiseReductionLevel {
	switch (level) {
		case sysApiCore.NoiseReductionLevel.Auto:
			return ChannelNoiseReductionLevel.Auto;
		case sysApiCore.NoiseReductionLevel.High:
			return ChannelNoiseReductionLevel.High;
		case sysApiCore.NoiseReductionLevel.Medium:
			return ChannelNoiseReductionLevel.Medium;
		case sysApiCore.NoiseReductionLevel.Low:
		default:
			return ChannelNoiseReductionLevel.Low;
	}
}

export function mapInputSourceFromSysApi(source?: sysApiCore.AudioChannelInputSourceType): InputSource {
	switch (source) {
		case sysApiCore.AudioChannelInputSourceType.Analog:
			return InputSource.Analog;
		case sysApiCore.AudioChannelInputSourceType.Digital:
			return InputSource.Digital;
		default:
			return InputSource.Digital;
	}
}

export function mapOutputSignalPathFromSysApi(path: sysApiCore.OutputSignalPath | undefined): ChannelOutputPath {
	switch (path) {
		case sysApiCore.OutputSignalPath.PostDigitalSignalProcessing:
			return ChannelOutputPath.PostDsp;
		case sysApiCore.OutputSignalPath.PreDigitalSignalProcessing:
		default:
			return ChannelOutputPath.PreDsp;
	}
}

export function mapSpatialSeparationFromSysApi(
	spatialSeparation: sysApiCore.StereoAudioChannelSpatialSeparation | null
): SpatialSeparation {
	switch (spatialSeparation) {
		case sysApiCore.StereoAudioChannelSpatialSeparation.Left:
			return SpatialSeparation.Left;
		case sysApiCore.StereoAudioChannelSpatialSeparation.Right:
			return SpatialSeparation.Right;
		default:
			return SpatialSeparation.Left;
	}
}

export function mapLoudspeakerFromSysApi(loudspeaker: sysApiCore.Loudspeaker | null | undefined): ChannelLoudspeaker {
	switch (loudspeaker) {
		case sysApiCore.Loudspeaker.Generic:
			return ChannelLoudspeaker.Generic;
		case sysApiCore.Loudspeaker.Mxp_1:
			return ChannelLoudspeaker.Mxp1;
		case sysApiCore.Loudspeaker.Mxp_3:
			return ChannelLoudspeaker.Mxp3;
		case sysApiCore.Loudspeaker.Mxp_5:
			return ChannelLoudspeaker.Mxp5;
		case sysApiCore.Loudspeaker.Mxp_6:
			return ChannelLoudspeaker.Mxp6;
		default:
			return ChannelLoudspeaker.Generic;
	}
}
