import { DateTime } from 'ts-luxon';

import { LicenseInfo } from '@shure/cloud/shared/models/devices';

import { DeviceLicenseInfoFragment, LicenseState as SysApiLicenseState } from '../graphql/generated/cloud-sys-api';

import { mapLicenseStateFromSysApi } from './map-license-state';

export function mapLicenseInfoFromSysApi(licenseInfos: DeviceLicenseInfoFragment[]): LicenseInfo | undefined {
	const validLicense = licenseInfos.find((license) => license.state === SysApiLicenseState.Valid);
	if (!validLicense) {
		return undefined;
	}

	return {
		count: validLicense.count,
		expirationDate: validLicense.expirationDate ? DateTime.fromISO(validLicense.expirationDate) : undefined,
		featureName: validLicense.featureName ?? undefined,
		perpetual: !!validLicense.perpetual,
		state: mapLicenseStateFromSysApi(validLicense.state)
	};
}
